import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
    constructor(private auth: AuthService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            !(req.url.includes(environment.auth.baseUrl) || req.url.includes(environment.http.apiBaseUrl)) ||
            req.url.includes(environment.http.translations) ||
            req.url.includes(environment.http.statusPage)
        ) {
            return next.handle(req);
        }
        return this.auth.csrfToken$.pipe(
            first((val) => val !== undefined),
            switchMap((value) =>
                next.handle(req.clone({ headers: req.headers.set('Authorization', `CSRF ${value}`) })).pipe(
                    catchError((e) => {
                        if (e instanceof HttpErrorResponse && e.status === 401) {
                            // refresh CSRF token and retry active request if fetching of token succeed
                            return this.auth.fetchNewCsrfToken().pipe(switchMap(() => this.intercept(req, next)));
                        }
                        throw e;
                    })
                )
            )
        );
    }
}
