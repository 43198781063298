import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeedbackDialogComponent } from './dialog/feedback-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MessagesModule } from '../messages/messages.module';
import { ArchiveService } from '../archive/archive.service';

@NgModule({
    declarations: [FeedbackDialogComponent],
    exports: [FeedbackDialogComponent],
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        MatDialogModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MessagesModule,
    ],
    providers: [ArchiveService],
})
export class FeedbackModule {}
