<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content>
    <p [innerHTML]="data.question | translate"></p>
    <mat-form-field *ngIf="data.reasons$">
        <mat-label>{{ 'DIALOGS.CONFIRMATION.REASON' | translate }}</mat-label>
        <mat-select [(value)]="reason">
            <mat-option *ngFor="let available of availableReasons$ | async" [value]="available">
                {{ available.displayValue }}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <p *ngIf="data.hint" [innerHTML]="data.hint | translate" class="content-hint"></p>
</div>
<div mat-dialog-actions class="justify-content-end">
    <button
        mat-raised-button
        color="primary"
        [mat-dialog-close]="reason?.value || true"
        [disabled]="data.reasons$ && !reason"
    >
        {{ data.confirm ? data.confirm : ('DIALOGS.CONFIRMATION.CONFIRM' | translate) }}
    </button>
    <button mat-button [mat-dialog-close]="false">
        {{ data.cancel ? data.cancel : ('DIALOGS.CONFIRMATION.CANCEL' | translate) }}
    </button>
</div>
