import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ArchiveService {
    constructor(private http: HttpClient) {}
    public archiveService(documentId: string, revision: number) {
        return this.http.delete(`${environment.http.apiBaseUrl}quote/${documentId}/revision/${revision}`);
    }

    public deleteVariantConfiguration(id: string) {
        return this.http.delete(`${environment.http.configuration}variant-configuration/configurations/${id}`);
    }
}
