import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmationDialogData } from './dialog/confirmation-dialog.component';
import { Subject } from 'rxjs';

export interface ConfirmationDialogOptions {
    title: string;
    question: string;
    width?: string;
    action: (string?) => void;
    reasons$?: Subject<{ [key: string]: string }>;
    cancel?: string;
    confirm?: string;
    hint?: string;
}

@Injectable()
export class ConfirmationService {
    constructor(private dialog: MatDialog) {}

    public showConfirmation({
        title,
        question,
        width,
        action,
        reasons$,
        cancel,
        confirm,
        hint,
    }: ConfirmationDialogOptions) {
        const data: ConfirmationDialogData = {
            title,
            question,
            reasons$,
            cancel,
            confirm,
            hint,
        };
        const config: MatDialogConfig = {
            disableClose: true,
            autoFocus: false,
            width,
            data,
        };
        this.dialog
            .open(ConfirmationDialogComponent, config)
            .afterClosed()
            .subscribe((answer) => {
                if (answer) {
                    action(answer);
                }
            });
    }
}
