import { Locale } from '../app/models/locale.model';

export const supportedLanguages: Locale[] = [
    { locale: 'de-DE', flag: 'de', salesOrg: '0500' },
    { locale: 'en-DE', flag: 'de', salesOrg: '0500' },
    { locale: 'de-AT', flag: 'at', salesOrg: '5000' },
    { locale: 'de-NL', flag: 'nl', salesOrg: '5900' },
    { locale: 'nl-NL', flag: 'nl', salesOrg: '5900' },
    { locale: 'de-BE', flag: 'be', salesOrg: '6000' },
    { locale: 'fr-BE', flag: 'be', salesOrg: '6000' },
    { locale: 'nl-BE', flag: 'be', salesOrg: '6000' },
    { locale: 'de-CH', flag: 'ch', salesOrg: '6200' },
    { locale: 'fr-CH', flag: 'ch', salesOrg: '6200' },
    { locale: 'it-CH', flag: 'ch', salesOrg: '6200' },
    { locale: 'da-DK', flag: 'dk', salesOrg: '6600' },
];
