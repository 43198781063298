import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessagesService } from './messages.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    declarations: [],
    imports: [CommonModule, MatSnackBarModule, TranslateModule.forChild()],
    providers: [MessagesService],
})
export class MessagesModule {}
