import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Locale } from '../../../models/locale.model';
import { AppService } from '../../../services/app-service';

@Component({
    selector: 'app-language-dropdown',
    templateUrl: './language-dropdown.component.html',
    styleUrls: ['./language-dropdown.component.scss'],
})
export class LanguageDropdownComponent implements OnInit {
    @Input()
    public selectedCountry: string;

    @Output()
    public languageChanged = new EventEmitter<Locale>();

    public availableLanguages = [];

    constructor(private appService: AppService) {}

    ngOnInit() {
        this.availableLanguages = this.appService.availableLanguages;
    }

    public selectLocale(language: Locale) {
        this.languageChanged.emit(language);
    }
}
