import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth.guard';
import { maintenanceGuard } from './guards/maintenance.guard';

const routes: Routes = [
    {
        path: '',
        canActivate: [authGuard, maintenanceGuard],
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        pathMatch: 'full',
    },
    {
        path: 'configuration',
        canActivate: [authGuard, maintenanceGuard],
        loadChildren: () => import('./modules/configuration/configuration.module').then((m) => m.ConfigurationModule),
    },
    {
        path: 'kmat',
        canActivate: [authGuard, maintenanceGuard],
        loadChildren: () => import('./modules/kmat/kmat.module').then((m) => m.KmatModule),
    },
    {
        path: 'commissioning',
        canActivate: [authGuard, maintenanceGuard],
        loadChildren: () => import('./modules/commissioning/commissioning.module').then((m) => m.CommissioningModule),
    },
    {
        path: 'next-steps',
        canActivate: [authGuard, maintenanceGuard],
        loadChildren: () => import('./modules/next-steps/next-steps.module').then((m) => m.NextStepsModule),
    },
    {
        path: 'maintenance',
        loadChildren: () => import('./modules/maintenance/maintenance.module').then((m) => m.MaintenanceModule),
        pathMatch: 'full',
    },
    {
        path: 'forbidden',
        loadChildren: () => import('./modules/forbidden/forbidden.module').then((m) => m.ForbiddenModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
