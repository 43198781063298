import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AppService } from './app-service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EmailService {
    constructor(private http: HttpClient, private appService: AppService) {}

    public getQuoteEmailText(
        documentId: string,
        revision: number,
        language: string,
        country: string
    ): Observable<{ text: string }> {
        return this.http.get<{ text: string }>(
            `${environment.http.apiBaseUrl}quote/${documentId}/revision/${revision}/mail-text`,
            {
                params: {
                    language,
                    country,
                },
            }
        );
    }

    public sendQuoteViaEmail(
        documentId: string,
        revision: number,
        payload: {
            emailReceivers: string[];
            emailCCReceivers: string[];
            emailBCCReceivers: string[];
            emailBody: string;
            language: string;
            country: string;
            salesOffice: string;
        }
    ) {
        return this.http.post(`${environment.http.apiBaseUrl}quote/${documentId}/revision/${revision}/mail`, payload);
    }

    public sendFeedback(sender: string, subject: string, message: string) {
        return this.sendEmail(sender, subject, message, 'feedback');
    }

    public sendIndividualOfferRequestEmail(sender: string, subject: string, message: string, type = 'maintenance') {
        return this.sendEmail(sender, subject, message, type);
    }

    private sendEmail(sender: string, subject: string, message: string, type: string) {
        const salesOrg = this.appService.getSalesOrg();
        const payload = {
            feedbackType: type,
            sender,
            subject,
            message,
            salesOrg,
        };
        return this.http.post(`${environment.http.apiBaseUrl}feedback`, payload);
    }
}
