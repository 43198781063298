<div class="root h-100 d-flex flex-column">
    <div class="navbar flex-grow-0" *ngIf="!embedded">
        <app-header></app-header>
    </div>
    <app-maintenance-message></app-maintenance-message>
    <div class="content flex-grow-1">
        <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
    </div>
    <dsw-footer cookie *ngIf="!embedded"></dsw-footer>
</div>
