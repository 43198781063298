<div>
    <button mat-button [matMenuTriggerFor]="menu1">
        <span class="d-none d-sm-inline">{{ selectedCountry }}</span>
        <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <mat-menu #menu1 overlapTrigger="false">
        <button *ngFor="let language of availableLanguages" (click)="selectLocale(language)" mat-menu-item>
            <span class="flag flag-{{ language.flag }}"></span>
            <span> {{ 'LANGUAGE_DROPDOWN.' + language.locale.toUpperCase() | translate }}</span>
        </button>
    </mat-menu>
</div>
