import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { ConfigitSettings, Features } from '../models/configit.model';
import { Locale } from '../models/locale.model';
import { SalesOrgInfo, UserInfo } from './permissions.service';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    public readonly localStorageKeyLanguage = 'sc_cultureLang';
    public readonly localStorageKeySalesorg = 'sc_salesOrg';
    constructor(private translate: TranslateService, private titleService: Title) {
        // initialize translate service, it will be overriden with correct user settings later
        this.translate.use('de-DE');
    }

    private settings: ConfigitSettings;

    public currentLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>(environment.app.defaultLanguage);

    public salesOrg$: BehaviorSubject<string> = new BehaviorSubject<string>(environment.configit.plant);

    public availableLanguages: Locale[] = [];

    public init(settings: ConfigitSettings, userInfo: UserInfo, salesOrgInfo: SalesOrgInfo) {
        let primarySalesOrg = salesOrgInfo.primarySalesOrg;

        this.settings = settings;
        this.translate.onLangChange.subscribe(() => {
            this.titleService.setTitle(this.translate.instant('HEADER.APP_TITLE'));
        });

        // filter supported languages by those that are allowed for salesOrg and user role
        this.availableLanguages = environment.app.supportedLanguages.filter(({ salesOrg }) => {
            const restrictions = this.findSalesArea(salesOrg)?.accessRestrictions;
            return restrictions && restrictions[userInfo.role] && salesOrgInfo.salesOrgs.includes(salesOrg);
        });

        if (primarySalesOrg === undefined) {
            primarySalesOrg = salesOrgInfo.salesOrgs.find((salesOrg) =>
                this.availableLanguages.some((language) => language.salesOrg === salesOrg)
            );
        }

        const defaultLang = environment.app.defaultLanguage;
        this.translate.setDefaultLang(environment.app.defaultLanguage);
        // set language based on local storage or browser language
        let language = localStorage.getItem(this.localStorageKeyLanguage);
        if (!language) {
            const defaultSalesOrg = this.availableLanguages.find((o) => o.salesOrg === primarySalesOrg);
            if (defaultSalesOrg) {
                language = defaultSalesOrg.locale;
            } else {
                // use browser language to find best matching language.
                // first try to find a match based on full locale ({lang}-{country}), if it's not supported, match only by {lang}
                const bestMatch = (lang: string) =>
                    this.availableLanguages.find(({ locale }) => locale.startsWith(lang));
                const supportedLanguage =
                    bestMatch(this.translate.getBrowserCultureLang()) || bestMatch(this.translate.getBrowserLang());
                language = supportedLanguage?.locale || defaultLang;
            }
        }
        this.setLanguage(language);
        let salesOrg = localStorage.getItem(this.localStorageKeySalesorg);
        if (!salesOrg) {
            salesOrg = this.availableLanguages.find((l) => l.locale === language)?.salesOrg || '0500';
        }
        this.setSalesOrg(salesOrg);
    }

    public getLanguage(): string {
        return this.currentLanguage$.value;
    }

    public getLanguageOnly() {
        return this.getLanguage().split('-')[0];
    }

    public getLocation() {
        return this.getLanguage().split('-')[1];
    }

    public setLanguage(languageAndCountry: string) {
        this.translate.use(languageAndCountry);
        localStorage.setItem(this.localStorageKeyLanguage, languageAndCountry);
        this.currentLanguage$.next(languageAndCountry);
    }

    public setSalesOrg(salesOrg: string) {
        if (salesOrg) {
            localStorage.setItem(this.localStorageKeySalesorg, salesOrg);
            this.salesOrg$.next(salesOrg);
        }
    }

    private getSalesArea() {
        const salesOrg = this.getSalesOrg();
        return this.findSalesArea(salesOrg);
    }

    private findSalesArea(salesOrg: string) {
        if (this.settings) {
            return this.settings.allowedSalesAreas.find((salesArea) => salesArea.salesOrganization === salesOrg);
        }
        return null;
    }

    public getSalesAreaId() {
        const salesArea = this.getSalesArea();
        if (!salesArea) {
            return '';
        }
        const { salesOrganization, distributionChannel, salesDivision } = salesArea;
        return `${salesOrganization}/${distributionChannel}/${salesDivision}`;
    }

    public getSalesOrg() {
        return this.salesOrg$.value;
    }

    public getSalesAreaName(): string {
        return this.getSalesArea()?.name;
    }

    public isMultivalenceForbidden() {
        return environment.app.multiValenceForbiddenSalesOrgs.includes(this.getSalesOrg());
    }

    public isShopEnabled() {
        return environment.app.shopEnabledSalesOrgs.includes(this.getSalesOrg());
    }

    public isFeatureEnabled(featureName: Features) {
        const features = this.getSalesArea().features;
        if (features) {
            const feature = features[featureName];
            return feature?.enabled || false;
        }
        return false;
    }
}
