import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app-service';
import { AuthService } from '../../../services/auth.service';
import { Observable, Subscription } from 'rxjs';
import { concatMap, delay, filter, map, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
    FeedbackDialogComponent,
    FeedbackDialogData,
    FeedbackDialogResult,
} from '../../shared/feedback/dialog/feedback-dialog.component';
import { EmailService } from '../../../services/email.service';
import { TranslateService } from '@ngx-translate/core';
import { MessagesService } from '../../shared/messages/messages.service';
import { Locale } from '../../../models/locale.model';
import { Router } from '@angular/router';
import { ConfirmationService } from '../../shared/confirmation/confirmation.service';
import { environment } from '../../../../environments/environment';
import { PermissionsService } from '../../../services/permissions.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    authenticated$: Observable<boolean>;
    public isUserEndcustomer: boolean;

    public faqLink: string;

    constructor(
        public appService: AppService,
        public auth: AuthService,
        private dialog: MatDialog,
        private translate: TranslateService,
        private emailService: EmailService,
        private messages: MessagesService,
        private confirmationService: ConfirmationService,
        private router: Router,
        protected permissionsService: PermissionsService
    ) {
        this.authenticated$ = this.auth.csrfToken$.pipe(
            delay(10),
            map((token) => {
                return !!token;
            })
        );
    }

    public ngOnInit() {
        this.permissionsService
            .isUserEndcustomer()
            .subscribe((isEndcustomer) => (this.isUserEndcustomer = isEndcustomer));
        this.updateFaqLink();
        this.appService.salesOrg$.subscribe(() => {
            this.updateFaqLink();
        });
    }

    private updateFaqLink() {
        const faq = environment.http.faq;
        const salesOrg = this.appService.getSalesOrg();
        this.faqLink = this.isUserEndcustomer
            ? faq.endcustomer[salesOrg] || faq.default
            : faq.customer[salesOrg] || faq.default;
    }

    public onLanguageChanged(locale: Locale): void {
        const url = this.router.url.split('?')[0];
        // change of sales org is only allowed on dashboard (change of language is fine as long as salesOrg stays the same)
        if (url === '/' || locale.salesOrg === this.appService.getSalesOrg()) {
            this.selectLocale(locale);
        } else {
            const title = this.translate.instant('HEADER.DIALOG.BACK_TO_DASHBOARD.TITLE');
            const question = this.translate.instant('HEADER.DIALOG.BACK_TO_DASHBOARD.QUESTION');
            this.confirmationService.showConfirmation({
                title,
                question,
                action: () => {
                    this.selectLocale(locale);
                    this.router.navigate(['/']);
                },
            });
        }
    }

    private selectLocale(locale: Locale) {
        this.appService.setLanguage(locale.locale);
        this.appService.setSalesOrg(locale.salesOrg);
    }

    public sendFeedback() {
        const title = this.translate.instant('DIALOGS.FEEDBACK.TITLE');
        const description = this.translate.instant('DIALOGS.FEEDBACK.DESCRIPTION');
        const dialogRef = this.dialog.open<FeedbackDialogComponent, FeedbackDialogData, FeedbackDialogResult>(
            FeedbackDialogComponent,
            {
                autoFocus: true,
                width: '600px',
                data: {
                    title,
                    description,
                },
            }
        );
        this.subscription = dialogRef
            .afterClosed()
            .pipe(
                filter((result) => !!result),
                concatMap((result) => this.emailService.sendFeedback(result.sender, result.subject, result.message)),
                take(1)
            )
            .subscribe(
                () => {
                    this.messages.showMessage('DIALOGS.FEEDBACK.MESSAGES.SEND.SUCCESS');
                },
                () => {
                    this.messages.showMessage('DIALOGS.FEEDBACK.MESSAGES.SEND.FAILURE', true);
                }
            );
    }

    public ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
