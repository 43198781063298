import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { InstanaService } from './services/instana.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    embedded = false;
    routeSubscribtion: any;
    constructor(private router: Router, private instana: InstanaService, private activatedRoute: ActivatedRoute) {}

    ngOnInit(): void {
        this.routeSubscribtion = this.activatedRoute.queryParams.subscribe((params) => {
            const { embedded } = params;
            if (embedded) {
                this.embedded = true;
                document.body.classList.add('embedded');
                if (!this.router.url.includes('forbidden')) {
                    this.router.navigate(['configuration/new'], { queryParamsHandling: 'merge' });
                }
            }
        });
        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.instana.reportPage(val.urlAfterRedirects);
            }
        });
    }

    onOutletLoaded(component) {
        component.embedded = this.embedded;
    }
}
