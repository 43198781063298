import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

export const authGuard: CanActivateFn = () => {
    const auth: AuthService = inject(AuthService);

    if (auth.hasValidCsrfToken()) {
        return of(true);
    }
    // start csrf token authentication process
    return auth.fetchNewCsrfToken().pipe(map((res) => !!res.token));
};
