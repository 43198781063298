import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MessagesService {
    constructor(private snackbar: MatSnackBar, private translate: TranslateService) {}

    public showMessage(translationKey: string, isError = false, duration = 3000) {
        const panelClass = isError ? ['error-snackbar'] : ['success-snackbar'];
        const message = this.translate.instant(translationKey);
        this.snackbar.open(message, '', {
            panelClass,
            duration,
        });
    }
}
