import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Reason {
    value: string;
    displayValue: string;
}

export interface ConfirmationDialogData {
    title: string;
    question: string;
    reasons$?: Subject<{ [key: string]: string }>;
    cancel?: string;
    confirm?: string;
    hint?: string;
}

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
    public availableReasons$: Observable<Reason[]>;
    public reason: Reason;

    constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {}

    ngOnInit(): void {
        if (this.data.reasons$) {
            this.availableReasons$ = this.data.reasons$.pipe(
                map((reasons) => Object.keys(reasons).map((key) => ({ value: key, displayValue: reasons[key] })))
            );
        }
    }
}
